<template>
  <div style="width: 100%;padding-top: 1%;min-height: 33vh;overflow: hidden;margin-bottom: 3%">
    <div style="width: 95%;margin: auto auto auto 9%">
      <!--  标题-->
      <div style="display: flex">
        <div style="width: 15%;display: flex">
          <div style="margin-top: -1%">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_tele_icon.png" style="width: 70%"/>
          </div>
          <span style="color: #0c89e5;font-size: 1.8em;font-weight: bolder;">远程会诊</span>
        </div>
        <div style="margin-top: 0.5%;font-size: 1em;margin-left: 1%;width: 100%;display: flex">
          <div>
            <div v-if="telemeetType !== '查看最新'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeTelemeetType('查看最新')">查看最新</span>
              </a>
            </div>
            <div v-if="telemeetType === '查看最新'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeTelemeetType('查看最新')">查看最新</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="telemeetType !== '查看全部'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeTelemeetType('查看全部')">查看全部</span>
              </a>
            </div>
            <div v-if="telemeetType === '查看全部'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeTelemeetType('查看全部')">查看全部</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  主体-->
      <div style="display: flex;margin-top: 2%">
          <div v-for="(item, index) in teleMeet" :key="index" style="width: 28%;min-height: 26vh;margin-right: 3%;background-color: #FFFFFF;border-radius: 10px;">
            <div style="display: flex;justify-content: space-between;margin: 5% 3% auto 5%">
              <div style="color: #0c89e5;font-weight: bolder;font-size: 1.2em">
                {{ item.meetTheme }}
              </div>
              <div style="color: #868686;margin-right: 5%">
                {{ item.meetTime}}
              </div>
            </div>
          <!--  会议图片-->
            <div style="text-align: center;margin-top: 2%">
              <img :src="item.url" style="width: 95%">
            </div>
          <!--  参会信息-->
            <div style="margin-left: 3%;margin-top: 3%;margin-bottom: 3%;font-size: 0.9em">
              <div>
                <span style="color: #868686;margin-right: 3%">参会部门:</span>
                <span>{{ item.meetDepartment}}</span>
              </div>

              <div style="margin-top: 2%">
                <span style="color: #868686;margin-right: 3%">参会专家:</span>
                <span>{{ item.meetExpert}}</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {uuid} from "@/utils/time";

export default {
  name: "telemeet",
  data() {
    return {
      telemeetType: '查看最新',
      teleMeet: []
    }
  },
  created() {
    this.getTeleMeet();
  },
  methods: {
    changeTelemeetType(val) {
      this.telemeetType = val;
      if (this.telemeetType === '查看全部') {
        this.$router.push({
          name: 'Tele_meet_detail',
          params: {
            key: uuid(),
          }
        });
      }
    },
    getTeleMeet() {
      this.axios.get('/teleconsultation/getLastTelmeet', (response) => {
        this.teleMeet = response.obj;
      })
    }
  }
}
</script>

<style scoped>

</style>