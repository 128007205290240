<template>
  <div style="width: 100%;background-color: #FFFFFF;min-height: 20vh;overflow: hidden;padding-bottom: 1%">
    <div style="width: 95%;margin: 2% auto auto 9%;">
      <!--  标题-->
      <div style="display: flex">
        <div style="width: 15%;display: flex">
          <div style="margin-top: -1%">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_res_icon.png" style="width: 70%"/>
          </div>
          <span style="color: #0c89e5;font-size: 1.8em;font-weight: bolder;">资源共享</span>
        </div>
        <div style="margin-top: 0.5%;font-size: 1em;margin-left: 1%;width: 100%;display: flex">
          <div>
            <div v-if="resShaType !== '全部资料'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeResShatType('全部资料')">全部资料</span>
              </a>
            </div>
            <div v-if="resShaType === '全部资料'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeResShatType('全部资料')">全部资料</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="resShaType !== '隧道专业'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeResShatType('隧道专业')">隧道专业</span>
              </a>
            </div>
            <div v-if="resShaType === '隧道专业'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeResShatType('隧道专业')">隧道专业</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="resShaType !== '桥梁专业'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeResShatType('桥梁专业')">桥梁专业</span>
              </a>
            </div>
            <div v-if="resShaType === '桥梁专业'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeResShatType('桥梁专业')">桥梁专业</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="resShaType !== '随笔'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeResShatType('随笔')">随笔</span>
              </a>
            </div>
            <div v-if="resShaType === '随笔'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeResShatType('随笔')">随笔</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <!--  搜索新增-->
          <div style="margin-left: 50%;margin-top: -0.3%;display: flex">
            <el-input size="small" v-model="fileName" autocomplete="off" placeholder="输入后按回车" clearable
                      @change="searchFile()"/>
            <el-button style="margin-left: 3%;margin-top: 0.5%;height: 1vh" type="primary" size="small"
                       @click="addFileDialog = true">上传资料&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;➕
            </el-button>
          </div>
        </div>
      </div>
      <!--  主体-->
      <div style="display: flex;flex-wrap: wrap;margin-top: 2%">
        <div v-for="(item, index) in resFiles" :key="index" style="width: 25%;margin-bottom: 2%">
          <div style="font-size: 1em;font-weight: bolder;width: 91%">
            <a href="javascript:void(0);" style="text-decoration: none;color: black"
               @click="openFile(item)">{{ item.fileName }}</a>
          </div>
          <div style="font-size: 0.8em;color: #868686;margin-top: 2%">
            <span>分&nbsp;&nbsp;享&nbsp;&nbsp;人&nbsp;:&nbsp;&nbsp;</span>
            <span style="margin-right: 10%">{{ item.upUsername }}</span>
          </div>
          <div style="font-size: 0.8em;color: #868686;margin-top: 2%">
            <span>分享时间&nbsp;&nbsp;:&nbsp;</span>
            <span>{{ item.upTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <!--  分页-->
    <div style="float: right;margin-right: 0.5%">
      <el-pagination v-model:currentPage="pagenum"
                     small
                     background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
    <!--  新增弹窗-->
    <div>
      <el-dialog
          v-model="addFileDialog"
          title="上传共享文件"
          width="50%"
          :close-on-click-modal="false"
          :show-close="false"
      >
        <!--弹窗主体-->
        <div style="display: flex">
          <div>
            <el-select v-model="type" class="m-2" placeholder="请选择文件分类" size="large">
              <el-option
                  v-for="item in types"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
          <div>
            <el-upload
                ref="upload"
                class="upload-demo"
                action="https://www.cr12cz.cn/czscsms/up/upload"
                :auto-upload="false"
                :data="dataup"
                :on-change="imgchange"
                :on-remove="handleRemove"
                :multiple="false"
                :on-success="upsuccess"
                :limit="1"
            >
              <el-button type="primary" style="margin-left: 35%">请选择问题附件</el-button>
            </el-upload>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDia()"> 取消 </el-button>
            <el-button type="primary" @click="addFileSha()"> 提交 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";

export default {
  name: "ressha",
  data() {
    return {
      resShaType: '全部资料',
      resFiles: [],
      pagenum: 1,
      total: 0,
      fileName: '',
      flag: 0,
      addFileDialog: false,
      type: '',
      types: [
        {
          value: '0',
          label: '隧道专业',
        },
        {
          value: '1',
          label: '桥梁专业',
        },
        {
          value: '2',
          label: '随笔',
        },
      ],
      dataup: {
        file: '',
        userid: ''
      },
      fileList: [],
      filename: '',
    }
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  created() {
    this.getFile();
  },
  methods: {
    changeResShatType(val) {
      this.resShaType = val;
      this.getFile();
      this.flag = 0;
    },
    getFile() {
      if (this.resShaType === '全部资料') {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: 1
        })
      }

      if (this.resShaType === '隧道专业') {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: 1,
          type: 0
        })
      }

      if (this.resShaType === '桥梁专业') {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: 1,
          type: 1
        })
      }

      if (this.resShaType === '随笔') {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: 1,
          type: 2
        })
      }
    },
    openFile(item) {
      this.$router.push({
        name: 'File_detail',
        params: {
          filename: item.fileName,
          fileurl: item.fileUrl,
          uptime: item.upTime,
          user: item.upUsername,
          id: item.id,
          userid: item.upUserid
        }
      });
    },
    searchFile() {
      this.flag = 1;
      this.axios.get('/ressha/getFile', (response) => {
        this.resFiles = response.obj;
        this.total = response.obj2.pages * 10;
      }, {
        pageCount: 1,
        fileName: this.fileName
      })
    },
    imgchange(file) {
      this.fileList.push(file);
      this.filename = file.name.split('.')[0];
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    upsuccess(response) {
      this.axios.post("/ressha/saveRessahe", (responseAdd) => {
        if (responseAdd.obj) {
          this.addFileDialog = false;
          this.getFiles();
          ElMessage({
            message: '上传成功!',
            type: 'success',
          })
        }
      }, {
        filename: this.filename,
        url: response.obj,
        userid: this.GET_USERID,
        username: this.GET_USER,
        type: this.type
      })
    },
    cancelDia() {
      this.addFileDialog = false;
      this.fileList = [];
    },
    addFileSha(){
      this.dataup.file = this.fileList[0].raw;
      this.dataup.userid = this.GET_USERID;
      this.$refs.upload.submit();
    },
  },
  watch: {
    pagenum() {
      if (this.flag === 0 && this.resShaType === '全部资料') {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: this.pagenum
        })
      }

      if (this.flag === 0 && this.resShaType === '隧道专业') {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: this.pagenum,
          type: 0
        })
      }

      if (this.flag === 0 && this.resShaType === '桥梁专业') {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: this.pagenum,
          type: 1
        })
      }

      if (this.flag === 0 && this.resShaType === '随笔') {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: this.pagenum,
          type: 2
        })
      }

      if (this.flag === 1) {
        this.axios.get('/ressha/getFile', (response) => {
          this.resFiles = response.obj;
          this.total = response.obj2.pages * 10;
        }, {
          pageCount: 1,
          fileName: this.fileName
        })
      }
    }
  }
}
</script>

<style scoped>

</style>