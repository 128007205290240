<template>
  <!--  专家团队-->
  <div id="bj_div">
    <!--居中效果-->
    <div style="width: 95%;margin: auto auto auto 9%;padding-top: 2%;">
      <!--  标题-->
      <div style="display: flex;">
        <div style="width: 12%;display: flex">
          <div style="margin-top: -1%">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_icon.png" style="width: 70%"/>
          </div>
          <span style="font-size: 1.8em;color: #FFFFFF;font-weight: bolder">专家团队</span>
        </div>
        <div style="margin-top: 0.5%;font-size: 1em;margin-left: 1%;width: 100%;display: flex">
          <div>
            <div v-if="expertType !== '全部专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #c1d6e5" @click="changeExperyType('全部专家')">全部专家</span>
              </a>
            </div>
            <div v-if="expertType === '全部专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #FFFFFF" @click="changeExperyType('全部专家')">全部专家</span>
              </a>
              <div style="border: 2px solid #FFFFFF;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="expertType !== '内部专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #c1d6e5" @click="changeExperyType('内部专家')">内部专家</span>
              </a>
            </div>
            <div v-if="expertType === '内部专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #FFFFFF" @click="changeExperyType('内部专家')">内部专家</span>
              </a>
              <div style="border: 2px solid #FFFFFF;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="expertType !== '外部专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #c1d6e5" @click="changeExperyType('外部专家')">外部专家</span>
              </a>
            </div>
            <div v-if="expertType === '外部专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #FFFFFF" @click="changeExperyType('外部专家')">外部专家</span>
              </a>
              <div style="border: 2px solid #FFFFFF;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="expertType !== '隧道专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #c1d6e5" @click="changeExperyType('隧道专家')">隧道专家</span>
              </a>
            </div>
            <div v-if="expertType === '隧道专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #FFFFFF" @click="changeExperyType('隧道专家')">隧道专家</span>
              </a>
              <div style="border: 2px solid #FFFFFF;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="expertType !== '外单位专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #c1d6e5" @click="changeExperyType('外单位专家')">外单位专家</span>
              </a>
            </div>
            <div v-if="expertType === '外单位专家'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #FFFFFF" @click="changeExperyType('外单位专家')">外单位专家</span>
              </a>
              <div style="border: 2px solid #FFFFFF;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <!--  搜索新增-->
          <div style="margin-left: 3%;margin-top: -0.2%;display: flex">
            <el-input size="mini" v-model="expertName" autocomplete="off" placeholder="输入专家姓名后按回车" clearable @change="searchExpert()"/>
            <el-button style="margin-left: 3%;margin-top: 0%;height: 1vh" type="info" size="small"
                       @click="addExpertMessageDio()">新增专家&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;➕</el-button>
          </div>
        </div>
      </div>
    <!--  专家照片-->
      <div style="display: flex;flex-wrap: wrap;width: 100%;margin: 2% auto auto auto">
        <div v-for="(item, index) in expert_message" :key="index" style="width: 30vh;background-color: #FFFFFF;margin-right: 3%;margin-bottom: 1.3%;border-radius: 10px;padding-right: 2%;position:relative;"
             @click="getmessage(item)" @contextmenu.prevent="delmessage(item)">
          <div style="position:absolute;right: 0">
            <img v-if="item.flag === '0'" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/jiao1.png" style="width: 102%"/>
            <img v-if="item.flag === '1'" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/jiao2.png" style="width: 102%"/>
            <img v-if="item.flag === '2'" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/jiao3.png" style="width: 102%"/>
            <img v-if="item.flag === '3'" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/jiao4.png" style="width: 102%"/>
          </div>
          <div style="display: flex;">
            <div>
              <img :src="item.imageUrl" style="width: 11vh;height: 12vh;margin: 15%"/>
            </div>
            <div style="margin-left: 12%;margin-top: 5%;line-height: 2.5vh;width: 50%">
              <div style=";color: #0c89e4;font-weight: bolder">{{ item.expertName }}</div>
              <div>专业:</div>
              <div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{item.expertProfession}}</div>
              <div>学位/学历:</div>
              <div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{item.degreeEducation}}</div>
            </div>
          </div>
          <div style="margin-left: 5%;margin-bottom: 2%;margin-top: -3%">
            <span style="font-weight: bolder;width: 100%">主要成就:&nbsp;&nbsp;</span>
            <div style="display: -webkit-box;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
              {{item.majorAchievements}}
            </div>
          </div>
        </div>
      </div>
    </div>
  <!--  分页-->
    <div style="position:relative;">
      <div style="position: absolute;bottom: 0;right: 0.1%;">
        <el-pagination v-model:currentPage="pagenum"
                       small
                       background layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>

    <!--专家信息-->
    <div v-if="show" style="position: absolute;width: 154vh;height: 90vh;background-color: #FFFFFF;top: 2%;left: 8%;padding-right: 3%;z-index: 999">
      <div style="position: absolute;top: 0.5vh;right: -8%" @click="close_dialog()">
        <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_close.png" style="width: 10%">
      </div>
      <div>
        <!--  图片、专业信息-->
        <div style="display: flex;margin-left: 5%">
          <div>
            <img :src="message.imageUrl" style="width: 250px;height: 300px;margin-top: 30%"/>
          </div>
          <div
              style="width: 80%;height: 49%;margin-left: 5%;margin-top: 5%;font-size: 1.3em;color: black;font-family: '苹方', serif">
            <div style="margin-bottom: 1%;color: #3573e6;font-weight: bolder;font-size: 1.6em">
              {{ message.expertName }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              专业：{{ message.expertProfession }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              学位/学历：{{ message.degreeEducation }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              毕业院校：{{ message.graduateSchool }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              职业：{{ message.occupation }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              职务：{{ message.post }}
            </div>
            <div style="font-weight: bolder;">
              主要成就：{{ message.majorAchievements }}
            </div>
          </div>
        </div>
        <div style="margin-top: 3%;margin-left: 5%;font-family: '苹方', serif">
          <div style="font-size: 2em;font-weight: bolder">
            详细介绍
          </div>
          <div style="font-size: 1.3em;line-height: 4vh;color: black;margin-top: 3%;font-weight: bolder">
            {{ message.briefIntroduction }}
          </div>
        </div>
      </div>
    </div>
  <!--  新增专家-->
    <div>
      <el-dialog
          v-model="addExperDialog"
          title="新增专家"
          width="50%"
          :close-on-click-modal="false"
          :show-close="false"
      >
        <el-form :model="formMessage" label-width="120px">
          <el-form-item label="专家姓名" prop="expertName">
            <el-input v-model="formMessage.expertName"/>
          </el-form-item>
          <el-form-item label="专业" prop="expertProfession">
            <el-input v-model="formMessage.expertProfession"/>
          </el-form-item>
          <el-form-item label="毕业院校" prop="graduateSchool">
            <el-input v-model="formMessage.graduateSchool"/>
          </el-form-item>
          <el-form-item label="学位/学历" prop="degreeEducation">
            <el-input v-model="formMessage.degreeEducation"/>
          </el-form-item>
          <el-form-item label="职务" prop="post">
            <el-input v-model="formMessage.post"/>
          </el-form-item>
          <el-form-item label="职业" prop="occupation">
            <el-input v-model="formMessage.occupation"/>
          </el-form-item>
          <el-form-item label="代表作" prop="representativeWork">
            <el-input v-model="formMessage.representativeWork"/>
          </el-form-item>
          <el-form-item label="主要成就" prop="majorAchievements">
            <el-input v-model="formMessage.majorAchievements"/>
          </el-form-item>
          <el-form-item label="个人简介" prop="briefIntroduction">
            <el-input v-model="formMessage.briefIntroduction"/>
          </el-form-item>
          <el-form-item label="专家分类" prop="expertFlag">
            <el-select v-model="formMessage.expertFlag" class="m-2" placeholder="请选择专家类别" size="large">
              <el-option
                  v-for="item in expertFlags"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>

        </el-form>
        <div>
          <el-upload
              ref="upload"
              class="upload-demo"
              action="https://www.cr12cz.cn/czscsms/up/upload"
              :auto-upload="false"
              :data="dataup"
              :on-change="imgchange"
              :on-remove="handleRemove"
              :multiple="false"
              :on-success="upsuccess"
              :limit="1"
          >
            <el-button type="primary" style="margin-left: 35%">请选择专家照片</el-button>
          </el-upload>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDia()"> 取消 </el-button>
            <el-button type="primary" @click="addExpertMessage()"> 提交 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  删除信息提示框-->
    <div>
      <el-dialog
          v-model="deldialogVisible"
          title="删除确认"
          width="30%"
      >
        <span style="font-size: 1.5em;color: black">{{ tooltips }}</span>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="deldialogVisible = false">取消</el-button>
        <el-button type="primary" @click="delExpertMessage()"> 确认 </el-button>
      </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";

export default {
  name: "expert_type",
  inject: ['reload'],
  data(){
    return{
      expertType: '全部专家',
      expert_message: [],
      pagenum: 1,
      total: 0,
      show: false,
      message: '',
      expertName: '',
      addExperDialog: false,
      dataup: {
        file: '',
        userid: ''
      },
      formMessage: {
        expertName: '',
        expertProfession: '',
        graduateSchool: '',
        degreeEducation: '',
        post: '',
        occupation: '',
        representativeWork: '',
        majorAchievements: '',
        briefIntroduction: '',
        expertFlag: '',
      },
      expertFlags: [
        {
          value: '0',
          label: '内部专家',
        },
        {
          value: '1',
          label: '外部专家',
        },
        {
          value: '2',
          label: '隧道专家',
        },
        {
          value: '3',
          label: '外单位专家',
        }
      ],
      fileList: [],
      deldialogVisible: false,
      flag: 0, // 区分是搜索还是整体   0 是整体  1 是搜索
    }
  },
  computed: {
    ...mapGetters(['GET_USERID'])
  },
  created() {
    this.getExpertMessage();
  },
  methods:{
    changeExperyType(val){
      this.expertType = val;
      this.pagenum = 1;
      this.flag = 0;
      this.getExpertMessage();
    },
    getExpertMessage(){
      if (this.expertType === '全部专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: 1
        })
      }

      if (this.expertType === '内部专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: 1,
          type: 0
        })
      }

      if (this.expertType === '外部专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: 1,
          type: 1
        })
      }

      if (this.expertType === '隧道专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: 1,
          type: 2
        })
      }

      if (this.expertType === '外单位专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: 1,
          type: 3
        })
      }
    },
    getmessage(val) {
      this.message = val;
      this.show = true;
    },
    close_dialog() {
      this.show = false;
      this.message = '';
    },
    delmessage(val) {
      this.tooltips = '是否确认删除 ' + val.expertName + ' 专家的信息?';
      this.delId = val.id;
      this.deldialogVisible = true;
    },
    searchExpert(){
      this.pagenum = 1;
      this.flag = 1;
      this.axios.post("/expertMessage/searchExpertByName", (response) => {
        this.expert_message = response.obj.records;
        this.total = response.obj.pages * 10;
      },{
        name: this.expertName,
        pageCount: this.pagenum
      })
    },
    addExpertMessageDio() {
      this.addExperDialog = true;
    },
    cancelDia(){
      this.addExperDialog = false;
    },
    addExpertMessage() {
      this.dataup.file = this.fileList[0].raw;
      this.dataup.userid = this.GET_USERID;
      this.$refs.upload.submit();
      this.reload();
    },
    imgchange(file) {
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    upsuccess(response) {
      const that = this;
      if (response.obj) {
        this.axios.post("/expertMessage/addExpertMessage", (responseAdd) => {
          if (responseAdd.obj) {
            this.addExperDialog = false;
            this.dataup.file = '';
            this.fileList = [];
            this.reload();
          }
        }, {
          expertName: that.formMessage.expertName,
          expertProfession: that.formMessage.expertProfession,
          graduateSchool: that.formMessage.graduateSchool,
          degreeEducation: that.formMessage.degreeEducation,
          post: that.formMessage.post,
          occupation: that.formMessage.occupation,
          representativeWork: that.formMessage.representativeWork,
          majorAchievements: that.formMessage.majorAchievements,
          briefIntroduction: that.formMessage.briefIntroduction,
          expertFlag: that.formMessage.expertFlag,
          imageurl: response.obj
        })
      }
    },
    delExpertMessage() {
      this.axios.post("/expertMessage/delExpertMessage", (response) => {
        if (response.obj) {
          this.deldialogVisible = false;
          this.reload();
        } else {
          ElMessage.error("删除失败，请联系科信部!");
        }
      }, {
        id: this.delId * 1
      })
    }
  },
  watch: {
    pagenum() {
      if (this.flag === 0 && this.expertType === '全部专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: this.pagenum
        })
      }

      if (this.flag === 0 && this.expertType === '内部专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: this.pagenum,
          type: 0
        })
      }

      if (this.flag === 0 && this.expertType === '外部专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: this.pagenum,
          type: 1
        })
      }

      if (this.flag === 0 && this.expertType === '隧道专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: this.pagenum,
          type: 2
        })
      }

      if (this.flag === 0 && this.expertType === '外单位专家'){
        this.axios.post('/expertMessage/getAllExpertPage', (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          pageCount: this.pagenum,
          type: 3
        })
      }

      if (this.flag === 1){
        this.axios.post("/expertMessage/searchExpertByName", (response) => {
          this.expert_message = response.obj.records;
          this.total = response.obj.pages * 10;
        },{
          name: this.expertName,
          pageCount: this.pagenum
        })
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-input__inner {
  background-color: transparent !important;
}

#bj_div{
  overflow: hidden;
  background: url('https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_top_bg.jpg');
  background-size: cover;
  //min-height: 55vh;
  /*position: relative;*/
}
</style>