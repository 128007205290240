<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <el-scrollbar height="95vh">
      <!--  专家团队-->
      <Expert_type></Expert_type>
      <!--  有问必答-->
      <Queorans></Queorans>
      <!--  远程会诊-->
      <Telemeet></Telemeet>
      <!--  资源共享-->
      <Ressha></Ressha>
    </el-scrollbar>


  </div>
</template>

<script>
import Header from "@/views/header1";
import Expert_type from "@/views/expert_consult/index/expert_type";
import Queorans from "@/views/expert_consult/index/queorans";
import Telemeet from "@/views/expert_consult/index/telemeet";
import Ressha from "@/views/expert_consult/index/ressha";
export default {
  components: {Header, Expert_type, Queorans, Telemeet, Ressha},
  name: "index",
  data(){
    return{
      expertType: ''
    }
  },
  created() {

  },
  methods:{
    changeExperyType(val){
      this.expertType = val;
    }
  }
}
</script>

<style scoped>

</style>