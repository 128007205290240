<template>
  <div style="width: 100%;background-color: #FFFFFF;min-height: 30.5vh;margin: auto;padding-top: 2%;overflow: hidden">
    <div style="width: 95%;margin: auto auto auto 9%">
    <!--  标题-->
      <div style="display: flex;">
        <div style="width: 12%;display: flex">
          <div style="margin-top: -1%">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_ans_icon.png" style="width: 70%"/>
          </div>
          <span style="font-size: 1.8em;color: #0b89e5;font-weight: bolder">有问必答</span>
        </div>
        <div style="margin-top: 0.5%;font-size: 1em;margin-left: 1%;width: 100%;display: flex">
          <div>
            <div v-if="questionType !== '全部问题'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black"><span style="color: #868686" @click="changeQuestionType('全部问题')">全部问题</span></a>
            </div>
            <div v-if="questionType === '全部问题'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeQuestionType('全部问题')">全部问题</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="questionType !== '已回答'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeQuestionType('已回答')">已回答</span>
              </a>
            </div>
            <div v-if="questionType === '已回答'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeQuestionType('已回答')">已回答</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="questionType !== '未回答'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeQuestionType('未回答')">未回答</span>
              </a>
            </div>
            <div v-if="questionType === '未回答'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeQuestionType('未回答')">未回答</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <div style="margin-left: 3%">
            <div v-if="questionType !== '我的'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #868686" @click="changeQuestionType('我的')">我的</span>
              </a>
            </div>
            <div v-if="questionType === '我的'">
              <a href="javascript:void(0); " style="text-decoration: none;color: black">
                <span style="color: #0b89e5" @click="changeQuestionType('我的')">我的</span>
              </a>
              <div style="border: 2px solid #0b89e5;margin-top: 8%;border-radius: 10%">
              </div>
            </div>
          </div>
          <!--  搜索新增-->
          <div style="margin-left: 50%;margin-top: -0.3%;display: flex">
            <el-input size="small" v-model="questionTitle" autocomplete="off" placeholder="输入问题后按回车" clearable @change="searchQuestion()"/>
            <el-button style="margin-left: 3%;margin-top: 0.5%;height: 1vh" type="primary" size="small"
                       @click="addQuestionDialog = true">新增问题&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;➕</el-button>
          </div>
        </div>
      </div>
    <!--  问题列表-->
      <div style="display: flex;flex-wrap: wrap;margin-top: 2%;">
        <div v-for="(item, index) in questions" :key="index" style="width: 25%;margin-bottom: 2%">
          <!--标题div-->
          <div style="display: flex">
            <a href="javascript:void(0);" style="text-decoration: none;color: black" @click="getQuestionDetail(item)"><span>{{item.questionTitle}}</span></a>
            <div style="margin-left: 3%;margin-top: 0.2%">
              <img v-if="item.answer_userid !== '0'" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_yes.png" style="width: 10%;">
              <img v-if="item.answer_userid === '0'" src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_question_inco.png" style="width: 10%;">
            </div>
          </div>
        <!--  提问信息div-->
          <div style="display:flex;font-size: 0.8em;margin-top: 1%;color: #868686">
            <div>
              <span>提问人:</span>
              <span>{{ item.questionUser }}</span>
            </div>
            <div style="margin-left: 5%">
              <span>提问时间:</span>
              <span>{{ item.questionTime }}</span>
            </div>
          </div>
        <!--  回答、附件情况div-->
          <div style="margin-top: 1%">
            <el-tag v-if="item.fileNum !== 0" size="mini" type="info">有附件</el-tag>
            <el-tag v-if="item.answer_userid !== '0'" size="mini" type="info" style="margin-left: 2%">已回答</el-tag>
            <el-tag v-if="item.answernum !== 0" size="mini" type="info" style="margin-left: 2%">{{item.answernum}}个回答</el-tag>
            <!--<a href="javascript:void(0);"><el-tag size="mini" type="info" style="margin-left: 2%;">我来回答</el-tag></a>-->
          </div>
        </div>
      </div>
    </div>
  <!--  分页-->
    <div style="float: right;margin-right: 0.5%">
      <el-pagination v-model:currentPage="pagenum"
                     small
                     background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
  <!--  新增问题-->
    <div>
      <el-dialog
          v-model="addQuestionDialog"
          title="有问题啦"
          width="50%"
          :close-on-click-modal="false"
          :show-close="false"
      >
        <!--弹窗主体-->
        <div>
          <div style="margin-bottom: 1%">
            <el-input
                v-model="question"
                autosize
                type="textarea"
                placeholder="请简要描述问题"
            />
          </div>
          <div v-show="question !== ''">
            <div style="border: 1px solid #ccc;">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="height: 500px; overflow-y: hidden;"
                  v-model="html"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
              />
            </div>
          </div>
          <div style="display:flex;justify-content: space-around;margin-top: 2%">
            <div>
              <el-upload
                  ref="upload"
                  class="upload-demo"
                  action="https://www.cr12cz.cn/czscsms/up/upload"
                  :auto-upload="false"
                  :data="dataup"
                  :on-change="imgchange"
                  :on-remove="handleRemove"
                  :multiple="false"
                  :on-success="upsuccessQuestion"
              >
                <el-button type="primary" style="margin-left: 35%">请选择问题附件</el-button>
              </el-upload>
            </div>
            <div>
              <el-select v-model="expertype" class="m-2" placeholder="专家类别" size="large" @change="getExpertName()">
                <el-option
                    v-for="item in expertypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>

              <el-select v-model="expertname" class="m-2" placeholder="专家姓名" size="large">
                <el-option
                    v-for="item in expertnames"
                    :key="item.id"
                    :label="item.expertName"
                    :value="item.expertName"
                />
              </el-select>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDia()"> 取消 </el-button>
            <el-button type="primary" @click="addQuestion()"> 提交 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {uuid} from "@/utils/time";
import {ElMessage} from "element-plus";
import {Editor, Toolbar} from '@wangeditor/editor-for-vue';
export default {
  components: {Editor, Toolbar},
  name: "queorans",
  inject: ['reload'],
  data(){
    return{
      questionType: '全部问题',
      questionTitle: '',
      pagenum: 1,
      total: 0,
      questions: [],
      addQuestionDialog: false,
      question: '',
      expertype: '',
      expertypes: [
        {
          value: '0',
          label: '内部专家',
        },
        {
          value: '1',
          label: '外部专家',
        },
        {
          value: '2',
          label: '隧道专家',
        },
        {
          value: '3',
          label: '外单位专家',
        }
      ],
      editor: null,
      html: '',
      toolbarConfig: {
        excludeKeys: [
          'group-image',
          'group-video',
          'group-insertLink'
        ]
      },
      editorConfig: {placeholder: '写下你的问题，准确的描述问题更容易获得解答'},
      mode: 'default', // or 'simple'
      dataup: {
        file: '',
        userid: ''
      },
      expertname: '',
      expertnames: [],
      question_uuid: '',
      fileList: []
    }
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  created() {
    this.getQuestion();
  },
  methods:{
    changeQuestionType(val){
      this.questionType = val;
      this.getQuestion();
      this.pagenum = 1;
      this.total = 0;
    },
    getQuestion(){
      if (this.questionType === '全部问题'){
        this.axios.get('/expertQuestion/getAllQuestion', (response) =>{
          this.questions = response.obj;
          this.total = response.obj2.pages * 10;
        },{
          pageCount: 1
        })
      }
      if (this.questionType === '已回答'){
        this.axios.get('/expertQuestion/getHaveAnswerQuestion', (response) => {
          this.questions = response.obj;
          this.total = response.obj2.pages * 10;
        },{
          type: 0,
          pageCount: 1
        })
      }
      if (this.questionType === '未回答'){
        this.axios.get('/expertQuestion/getNoAnswerQuestion', (response) => {
          this.questions = response.obj;
          this.total = response.obj2.pages * 10;
        },{
          type: 0,
          pageCount: 1
        })
      }
      if (this.questionType === '我的'){
        this.axios.get('/expertQuestion/getAllQuestion', (response) => {
          this.questions = response.obj;
          this.total = response.obj2.pages * 10;
        },{
          userid: this.GET_USERID,
          pageCount: 1
        })
      }
    },
    getQuestionDetail(val){
      this.$router.push({
        name: 'Answer_detail',
        params: {
          key: val.id,
          flag: '1',
          question: val.question,
          title: val.questionTitle,
          question_userid: val.question_userid,
          questionUser: val.questionUser,
          questionTime: val.questionTime,
          fileNum: val.fileNum
        }
      });
    },
    searchQuestion(){
      this.axios.get('/expertQuestion/getAllQuestion', (response) => {
        this.questions = response.obj;
        this.total = response.obj2.pages * 10;
      },{
        questionTitle: this.questionTitle,
        pageCount: 1
      })
    },
    imgchange(file) {
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    cancelDia() {
      this.addQuestionDialog = false;
      this.question = '';
      this.dataup.file = [];
      this.fileList = [];
      this.expertype = '';
      this.expertname = '';
      this.html = '';
    },

    addQuestion() {
      this.dataup.userid = this.GET_USERID;
      this.question_uuid = uuid();
      this.axios.post('/expertQuestion/addQuestion', (response) => {
        if (response.obj) {
          if (this.fileList.length > 0) {
            this.html = '';
            this.question = '';
            this.expertname = '';
            this.expertype = '';
            this.dataup.file = [];
            this.fileList = [];
            this.$refs.upload.submit();
            this.addQuestionDialog = false;
            ElMessage({
              message: '提问成功!',
              type: 'success',
            });
            this.reload();
          } else {
            this.addQuestionDialog = false;
            ElMessage({
              message: '提问成功!',
              type: 'success',
            })
          }
        } else {
          ElMessage.error("添加失败，请联系科信部!")
        }
      }, {
        question: this.html,
        question_title: this.question,
        uuid: this.question_uuid,
        username: this.GET_USER,
        userid: this.GET_USERID,
        specialuser: this.expertname,
        filenum: this.fileList.length
      })

    },
    upsuccessQuestion(response) {
      this.axios.post("/expertQuestionMaterial/addQuestionMaterial", (responseAdd) => {
        if (responseAdd.obj) {
          this.addQuestionDialog = false;
          ElMessage({
            message: '提问成功!',
            type: 'success',
          })
        }
      }, {
        question_uuid: this.question_uuid,
        url: response.obj,
        name: response.message
      })
    },
    getExpertName() {
      this.axios.post('/expertMessage/getExpertName', (response) => {
        this.expertnames = response.obj;
      }, {
        flag: this.expertype
      })
    },
    openUrl(val){
      window.open(val, "_blank");
    },
  },
  watch: {
    pagenum() {
      if (this.questionType === '全部问题'){
        this.axios.get('/expertQuestion/getAllQuestion', (response) =>{
          this.questions = response.obj;
          this.total = response.obj2.pages * 10;
        },{
          pageCount: this.pagenum
        })
      }
      if (this.questionType === '已回答'){
        this.axios.get('/expertQuestion/getHaveAnswerQuestion', (response) => {
          this.questions = response.obj;
          this.total = response.obj2.pages * 10;
        },{
          type: 0,
          pageCount: this.pagenum
        })
      }
      if (this.questionType === '未回答'){
        this.axios.get('/expertQuestion/getNoAnswerQuestion', (response) => {
          this.questions = response.obj;
          this.total = response.obj2.pages * 10;
        },{
          type: 0,
          pageCount: this.pagenum
        })
      }
      if (this.questionType === '我的'){
        this.axios.get('/expertQuestion/getAllQuestion', (response) => {
          this.questions = response.obj;
          this.total = response.obj2.pages * 10;
        },{
          userid: this.GET_USERID,
          pageCount: this.pagenum
        })
      }
    }
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>